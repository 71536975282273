import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import API from './api/API';
import { useConfig } from './contexts/Config/context';
import { UserContext } from './contexts/User';
import userConstants from './contexts/User/userConstants';
import { enableIndexRobots } from './data/constants';
import handler from './helpers/handler';
import storageHelper from './helpers/storageHelper';
import useSnackbarGD from './hooks/useSnackbar';
import FAQ from './resources/about-us/FAQ';
import Checkout from './resources/checkout/components/Checkout';
import CompleteOrder from './resources/completeOrder/components/CompleteOrder';
import FreeShippingPopup from './resources/components/FreeShippingPopup';
import DeleteAccount from './resources/delete-account';
import EasyBuilder from './resources/easy-builder/components/EasyBuilder';
import Home from './resources/home/Home';
import NotFound from './resources/not-found';
import ProBuilder from './resources/pro-builder/components/ProBuilder';
import ProductDetail from './resources/product/ProductDetail';
import ProductsCategories from './resources/products-page/ProductsCategories';
import ProductsPage from './resources/products-page/ProductsPage';
import Promotions from './resources/promotions';
import RegretPolicy from './resources/regret-policy';
import UserEditData from './resources/user/UserEditData';
import UserProfile from './resources/user/UserProfile';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '67px',
        '@media (max-width: 600px)': {
            marginTop: '59px',
        },
    },
}));

const GDRouter = () => {
    const [state, dispatch] = useContext(UserContext);
    const { fetchConfigs } = useConfig();
    const navigate = useNavigate();
    const location = useLocation();
    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
    const classes = useStyles();
    const [afterLogin, setAfterLogin] = useState(query.has('afterLogin'));
    const [afterLoginChecked, setAfterLoginChecked] = useState(false);
    const { showSnackbarMessage } = useSnackbarGD();

    useEffect(() => {
        fetchConfigs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAfterLoginChecked(false);
        if (afterLogin) {
            if (!state.user.role) {
                const cart = storageHelper.getUserCart();
                API.users
                    .me()
                    .then((response) => {
                        const user = {
                            email: response.data.email,
                            role: response.data.role,
                            profileImageURL: response.data.profileImageURL,
                            shippingData: response.data.shippingData,
                        };
                        const googleLoginRedirect = localStorage.getItem('redirectAfterGoogleLogin');
                        storageHelper.clearWaitGoogleLogin();
                        dispatch({ type: userConstants.SET_USER, user });
                        setAfterLogin(false);
                        storageHelper.setUser(user);
                        if (cart?.id) API.carts.updateCart(cart);
                        else API.carts.post();

                        showSnackbarMessage(`Bienvenido, ${user.email}`, 'success');

                        if (googleLoginRedirect) {
                            navigate(googleLoginRedirect);
                        }
                    })
                    .catch((error) => {
                        API.auth.logout();
                        handler.handleError({
                            error,
                            userContextDispatch: dispatch,
                            showSnackbarMessage,
                        });
                        dispatch({ type: userConstants.LOGOUT });
                        storageHelper.clearStorage();
                        setAfterLogin(false);
                    })
                    .finally(() => setAfterLoginChecked(true));
            }
        } else {
            storageHelper.clearWaitGoogleLogin();
            setAfterLoginChecked(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, state.user, afterLogin, navigate]);

    return afterLoginChecked ? (
        <>
            <Helmet>
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content={enableIndexRobots ? 'index, follow' : 'noindex, nofollow'} />
            </Helmet>
            <Box className={classes.root}>
                <FreeShippingPopup />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/ofertas/:productTypeSlug?" element={<ProductsPage isOffersPage />} />
                    <Route path="/productos/:productTypeSlug?" element={<ProductsPage />} />
                    <Route path="/producto/:slug" element={<ProductDetail />} />
                    <Route path="/easybuilder" element={<EasyBuilder />} />
                    <Route path="/probuilder/:fromPersonalize?" element={<ProBuilder />} />
                    <Route path="/categorias" element={<ProductsCategories />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/recoverPassword/:token" element={<Home openRecoverPassword />} />
                    <Route path="/CompleteOrder" element={<CompleteOrder />} />
                    <Route path="/nosotros" element={<FAQ />} />
                    <Route path="/promos" element={<Promotions />} />
                    <Route path="/politicas-de-devolucion" element={<RegretPolicy />} />
                    <Route path="/eliminar-cuenta" element={<DeleteAccount />} />
                    <Route exact path="/account/edit" element={<UserEditData />} />
                    <Route exact path="/account" element={<UserProfile />} />
                    <Route exact path="/not-found" element={<NotFound />} />
                    <Route path="*" element={<Navigate to="/not-found" replace />} />
                </Routes>
            </Box>
        </>
    ) : (
        <CircularProgress />
    );
};

export default GDRouter;
